import React from 'react';
import ReactDOM from 'react-dom/client';
import './App.css';
import './style.css';
import './Tabs.css';  // Ensure Tabs.css is imported
import Tabs from './Tabs';

const Letter = () => {
    return (
        <div className="letter-container">
            <div>
                <h1 className="letter-heading title-type">Dear Kate,</h1>
                <div className="letter-body">
                    <p className="intro">Happy Sunday!</p>
                    <p className="body1">
                        Given the day of the week, it's definitely time for a note! For starters, I'm sorry that it's been a
                        tough relationship week. I know that we've both been very busy, but I'm very grateful that I get to
                        continue to call you mine every day. You are my best friend and my rock. I love you.
                    </p>
                    <p className="body1">
                        You are the most amazing person I know. You are kind, caring, and loving. You are the best thing
                        that has ever happened to me.
                    </p>
                    <p className="body1">
                        When I see you, I think about every moment of love I've felt for you. We've had good days and bad
                        days. Some days the sun was bright, others it poured. No matter what, I was always happy to have you
                        by my side and in my life.
                    </p>
                    <p className="body1">
                        Thank you for being my partner, my confidant, and my biggest supporter. I hope that next week is a
                        step in the right direction from this week :)
                    </p>
                </div>
                <p className="letter-closing">
                    With all my love,<br/>
                    Sam
                </p>
            </div>
        </div>
    );
};

const NewNote = () => {
    return (
        <div className="new-note-container">
            <div>
                <h1 className="letter-heading title-type">Dear Kate,</h1>
                <div className="letter-body">
                    <p className="intro">Happy Sunday pt. 2!</p>
                    <p className="body1">
                        This Sunday you're a little far from home as you know. But, that doesn't mean I'm going to miss my note!
                        It was a tough week and I'm excited for you to come home tomorrow. I also can't wait to hear about your trip
                        and I hope that you've been able to find some fun there despite your expectations.
                    </p>

                    <p className="body1">
                        This weekend, I've been seeing you everywhere I go. I took a nighttime trip to Mercury's and thought about
                        you the whole time. I got an emerald city because it reminded me of you. Bad decision - I was up all night :)
                        It reminded me of our late-night conversations and trips there.
                    </p>

                    <p className="body1">
                        Despite the distance, thinking about you makes my heart feel warm. I'm counting down the hours until you come back home. I miss you deeply, and I can't wait to see you.
                    </p>

                    <p className="body1">
                        Tomorrow can't come soon enough. I’m so excited to see you and hear all about your trip.
                    </p>

                    <p className="body1">
                        Take care, my love, and have a safe drive home. I’ll be here waiting to hug you and make up for the time that we've been apart.
                    </p>
                </div>
                <p className="letter-closing">
                    With lots of love,<br/>
                    Sam
                </p>
            </div>
        </div>
    );
};

function App() {
    return (
        <div className="App">
            <Tabs>
                <div label="4/28/2024">
                    <Letter />
                </div>
                <div label="06/23/2024">
                    <NewNote />
                </div>
            </Tabs>
        </div>
    );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App/>);

export default App;
